<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="primary"
    flat
    height="80"
    v-if="user.isLoggedIn"
  >
    <v-btn
      color="lightgray"
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
      v-if="user.isLoggedIn"
    >
      <v-icon color="black" v-if="drawer"> mdi-menu-open </v-icon>

      <v-icon color="black" v-else> mdi-menu </v-icon>
    </v-btn>
    <div class="d-flex align-center" v-if="!user.isLoggedIn">
      <v-btn x-large text to="/" color=""><b>Kredwise Finance</b></v-btn>
    </div>
    <v-toolbar-title
      v-if="user.isLoggedIn"
      class="hidden-sm-and-down font-weight-light lightgray--text"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2 lightgray--text"
      min-width="0"
      text
      to="/dashboard"
      v-if="user.isLoggedIn"
    >
    <b><u>{{ user.username }}</u></b>{{ "  ." }}
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>
    <v-col cols="auto">
      <v-btn
        class="mb-2 lightgray--text"
        text
        @click="logout()"
        v-if="user.isLoggedIn"
      >
        <span class=""
          >Logout
          <v-icon>mdi-location-exit</v-icon>
        </span>
      </v-btn>
    </v-col>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["user", "drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    async logout() {
      await this.$store.dispatch("user/logout");
      if (!this.user.isLoggedIn) {
        this.$router.push("/");
      }
    },
  },
};
</script>
